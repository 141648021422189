import React from 'react'
import useForm from '@hooks/useForm'
import {
  FormUser,
  initialValues,
  initialValidation,
  TFormUser
} from '@components/Form/FormUser'
import GenericFormPage from '@templates/Generic/Form'
import { Button } from 'gatsby-material-ui-components'
import { ApiResponse, createUser } from '@utils/api'
import { Grid } from '@mui/material'
import useApi from '@hooks/useApi'

const SettingsUsersAddPage = () => {
  const form = useForm<TFormUser>({
    initialValues,
    initialValidationRules: initialValidation
  })

  const userApi = useApi<ApiResponse<string>>({
    apiMethod: createUser,
    requestOnMount: false
  })

  return (
    <GenericFormPage
      title="Add New User"
      feature="users"
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Users Management',
          to: '/settings/users/'
        },
        {
          title: 'Add New User'
        }
      ]}
      form={form as any}
      api={userApi}
      successMessage="User successfully added."
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              data-cy="submitNewUser"
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={userApi.loading}
            >
              Add User
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormUser includeRole={true} form={form as any} />
    </GenericFormPage>
  )
}

export default SettingsUsersAddPage
